$base_font_family: 'inherit';
$base_font_size: 1.4rem;

@mixin font-15 {
  font-size: 1.5rem;
  line-height: 20px;
}

@mixin font-22 {
  font-size: 2.2rem;
  line-height: 27px;
}

@mixin semi-bold-text {
  font-weight: 600;
}

@mixin ccl-center {
  text-align: center;
}

@mixin text-uppercase {
  text-transform: uppercase;
}

@mixin text-center {
  text-align: center;
}

@mixin bold-txt {
  font-weight: 700;
}

@mixin clearfix {
  clear: both;

  &:before,
  &:after {
    content: ' ';
    display: table;
  }

  &:after {
    clear: both;
  }
}
