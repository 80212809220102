@import '../../CometChat/resources/mixins';
@import '../../CometChat/resources/colors';

.ccl-dtls-panel-wrap {
  .ccl-right-panel-head-wrap {
    padding: 20px 16px;
    position: relative;

    .ccl-right-panel-head-ttl {
      margin: 0;
      font-weight: 700;
      letter-spacing: -0.5px;
      display: inline-block;
    }

    .cc1-right-panel-close {
      cursor: pointer;
      display: none;
      background: url('./resources/navigate_before.svg') center center no-repeat;
      width: 24px;
      height: 24px;
      float: left;
      margin: 1px 10px 0 0;
    }
  }

  .ccl-dtls-panel-body {
    padding: 16px;

    .ccl-dtls-noti-sel-wrap {
      margin-bottom: 30px;

      input[type='checkbox'] {
        display: none;
      }

      input[type='checkbox'] + label {
        display: block;
        cursor: pointer;
        background: url(./resources/checkbox-inactive.svg) right center / 16px
          no-repeat;
        font-size: 15px;
        letter-spacing: -0.1px;
        line-height: 23px;
        user-select: none;
        @include semi-bold-text;
      }

      input[type='checkbox']:checked + label {
        background: url(./resources/checkbox-blue-active.svg) no-repeat right
          center;
        background-size: 16px;
      }
    }

    .ccl-dtls-panel-section {
      margin-bottom: 30px;

      .ccl-dtls-panel-section-head {
        margin: 0;
        font-size: 12px;
        letter-spacing: -0.1px;
        line-height: 20px;
        color: $secondary;
        @include text-uppercase;
      }

      .ccl-dtls-section-list {
        .ccl-dtls-section-listitem-link {
          font-size: 15px;
          letter-spacing: -0.1px;
          line-height: 20px;
          padding: 6px 0;
          display: inline-block;
          @include semi-bold-text;
        }
      }
    }

    .ccl-dtls-panel-section.actions {
      .ccl-dtls-section-list {
        .ccl-dtls-section-listitem-link {
          color: $green;
          @include semi-bold-text;
        }
      }
    }

    .ccl-dtls-panel-section.privacy {
      .ccl-dtls-section-list {
        .ccl-dtls-section-listitem-link {
          color: $red;
          @include semi-bold-text;
          cursor: pointer;
        }
      }
    }
    .ccl-dtls-panel-section {
      .ccl-dtls-panel-media-fltr-wrap {
        margin-top: 8px;

        .ccl-dtls-panel-media-fltrs {
          border-radius: 8px;
          background-color: rgba(20, 20, 20, 0.08);
          width: 100%;
          padding: 2px;
          margin-bottom: 10px;

          .ccl-dtls-panel-media-fltr-btn {
            display: inline-block;
            width: 33.33%;
            float: left;
            font-size: 13px;
            font-weight: 500;
            letter-spacing: -0.1px;
            line-height: 18px;
            padding: 5px;
            position: relative;
            @include ccl-center;
            &::before {
              content: '';
              position: absolute;
              display: block;
              width: 2px;
              height: 16px;
              background-color: rgba(20, 20, 20, 0.12);
              right: -2px;
              top: 6px;
            }
          }

          .ccl-dtls-panel-media-fltr-btn.active {
            background-color: #fff;
            box-shadow: rgba(20, 20, 20, 0.04) 0 3px 1px,
              rgba(20, 20, 20, 0.12) 0 3px 8px;
            border-radius: 7px;

            &::before {
              display: none;
            }
          }

          .ccl-dtls-panel-media-fltr-btn:last-child {
            &::before {
              display: none;
            }
          }
        }

        .ccl-dtls-panel-media-type-wrap.photos {
          .ccl-dtls-panel-media-item {
            display: inline-block;
            float: left;
            width: 120px;
            border-radius: 8px;
            overflow: hidden;
            margin-bottom: 7px;
          }
          .ccl-dtls-panel-media-item:nth-child(2n + 1) {
            margin-right: 7px;
          }
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .cc1-right-panel-close {
    display: block !important;
  }
}
