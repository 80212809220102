.MuiAutocomplete-endAdornment {
  top: calc(50% - 1rem) !important;
}

.MuiGrid-root {
  display: flex;
}

.MuiAutocomplete-listbox > li:first-child:empty {
  display: none;
}
