$white: #ffffff;

$secondary: rgba(20, 20, 20, 0.6);
$dark-secondary: rgba(255, 255, 255, 0.6);

$grey: rgba(20, 20, 20, 0.04);
$light-grey: rgba(255, 255, 255, 0.08);

$light-border: rgba(20, 20, 20, 0.1);
$dark-border: rgba(255, 255, 255, 0.1);

$dark-grey: rgba(20, 20, 20, 0.9);

$green: #1bc5bd;
$blue: #39f;
$light-background: #ffffff;
$dark-background: #141414;

$red: #ff3b30;

$text-dark: #141414;

$light-background-border: #eaeaea;
$dark-background-border: #e8e8e8;

$gray-lighten: #e8e8e8;
// $gray-darken: #eaeaea;

$text-darken-more: #514d6a;
$white: #ffffff;
$light-primary-text: #000;
$light-background: #fff;
$gray-lighten-more-more: #f6f6f6;
$gray-lighten: #e8e8e8;
$gray-darken: #212121;
$dark-primary-text: #fff;
$dark-background: #141414;
$blue-background-primary: #39f;
