// 3rd-party Plugins
@import '~highlight.js/styles/googlecode.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Theme Styles
@import './_metronic/_assets/sass/style.react.scss';

@import './material-ui.scss';

// Default Layout themes
// @import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// @import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";
// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";
// Brand themes
// .brand-dark {
//   @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// }
// // Light
// .brand-light {
//   @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
// }
// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";
// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }
.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.aside-nav {
  flex: 1;
}

@media (max-width: 991.98px) {
  body .aside.aside-left {
    transition: get($aside-config, base, transition);
  }

  body.aside-minimize .aside.aside-left {
    transition: get($aside-config, base, transition);
    width: 70px;
  }
}

@media (max-width: 325px) {
  .aside,
  body.aside-minimize .aside {
    width: 70px !important;
  }

  .aside.aside-on {
    width: 90% !important;
  }
}

table th.sortable > span.svg-icon {
  position: absolute;
}

.menu-link.active .menu-text {
  color: #1bc5bd !important;
}

.menu-link {
  height: calc(1.5em + 1.65rem + 2px);
  min-height: calc(1.5em + 1.65rem + 2px) !important;
}

.btn.btn-light i {
  color: #b5b5c3;
}

.btn.btn-sm i,
.btn-group-sm > .btn i {
  font-size: 1.2025rem;
}

.table td.fit,
.table th.fit {
  white-space: nowrap;
  width: 1%;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding-right: 50px !important;
}

.MuiAutocomplete-endAdornment {
  top: calc(50% - 0.95rem) !important;
}

#kt_quick_user {
  max-width: 300px;
}

.btn.btn-sm i,
.btn-group-sm > .btn i,
.btn-group-sm > .btn i {
  font-size: 16px;
}

.MuiAutocomplete-tagSizeSmall {
  margin: 0 !important;
}

[name] {
  scroll-margin: 20px !important;
}

@media (max-width: 425px) {
  .offcanvas {
    width: 0% !important;
  }

  .form-group.row {
    width: 100% !important;
  }

  .card-body .react-bootstrap-table.table-responsive {
    position: relative;
    // padding: 0 0px;
  }

  .card-body .form.form-label-right {
    // padding: 25px 0 0 25px;
  }

  .card-body .react-bootstrap-table.table-responsive + div {
    // padding: 0 0px;
  }

  .card-body .react-bootstrap-table.table-responsive + div .invisible {
    display: none !important;
  }
}

@media (max-width: 960px) {
  .container {
    padding: 0px;
    .card {
      margin-bottom: 0;
    }
  }

  .card-body .form.form-label-right .form-group {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .content {
    padding: 0px;
  }

  .d-flex.flex-column.flex-row-fluid.wrapper {
    .content > .subheader {
      display: none;
    }

    .footer {
      display: none !important;
    }
  }
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.4) !important;
}
